import user from "@/api/user";
import { EventType } from "./const";
import { TraceId } from "./traceId";

export interface StatisticData {
  eventName?: string;
  eventType?: number;
  path?: string;
  param?: object;
}

export const statistic = ({
  eventName,
  eventType = EventType.Click,
  path = window.location.href,
  param
}: StatisticData) => {
  const data = {
    traceId: TraceId.getId(),
    eventName,
    eventType,
    path,
    param
  };

  TraceId.increase();

  user.reportAnIncident(data);

  return true;
};
