import http from "./instance";

export interface IUchoiceMemberByPageParamsProps extends Partial<IBaseSearchConditionProps> {
  sortDirection: string;
}

export interface IOatuthTokenParamsProps {
  username: string;
  grant_type: string;
  password: string;
  nation: string;
  cloudflare_token: string;
}

export interface ReportAnIncidentParams {
  traceId?: string;
  eventName?: string;
  param?: object;
  path?: string;
  eventType?: number;
}

export default {
  getUchoiceMemberByPage: (params: IUchoiceMemberByPageParamsProps): any => {
    return http.get<IUchoiceMemberByPageParamsProps>(
      "/api-uchoice/uChoice/member/getUchoiceMemberByPage",
      params
    );
  },
  oatuthToken: (data: IOatuthTokenParamsProps): any => {
    return http.post<IOatuthTokenParamsProps>("/api-uaa/oauth/token", data);
  },
  usersCurrent: (): any => {
    return http.get<IUchoiceMemberByPageParamsProps>("/api-user/users/current");
  },
  reportAnIncident: (data: ReportAnIncidentParams): any => {
    const truthyData = {
      ...data,
      param: data.param ? JSON.stringify(data.param) : undefined
    };
    return http.postJSON<any>("/api-member/toonMember/reportAnIncident", truthyData);
  }
};
