export const EventType = {
  Click: 1,
  View: 2
};

export const EventName = {
  leave: "leave",
  return: "return",
  close: "close",
  share_product_selection: "share_product_selection",
  get_tap_product_link: "get_tap_product_link"
};
