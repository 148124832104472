import React, { useEffect, useMemo, useState } from "react";
import { Modal, Radio } from "antd";
import { useIntl } from "umi";
import userManage from "@/api/userManage";
import { getShareLinkNew } from "@/utils/getShareLink";
import { copy } from "@/utils/copy";
import { getH5BaseUrl } from "@/utils/getH5BaseUrl";
import { RegionParam } from "@/utils/regionParam";
import { statistic } from "@/utils/statistic";
import { EventName } from "@/utils/statistic/const";

interface Props {
  visible: boolean;
  handleCancel: () => void;
  record: any;
}

export const ShareModal: React.FC<Props> = ({ visible, handleCancel, record }) => {
  const intl = useIntl();
  const [style, setStyle] = useState(0);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [tapStatus, setTapStatus] = useState(0);
  const [isBatchAdd, setIsBatchAdd] = useState(false);

  const getTapStatus = async () => {
    const { code, result } = await userManage.getItemSetTopicPage({
      itemSetId: record.id
    });
    if (code === 200) {
      setTapStatus(result.tapStatus);
    }

    {
      const { code, result } = await userManage.requestItemSetInfo({
        itemSetId: record.id
      });
      if (code === 200 && result) {
        setIsBatchAdd(result.isBatchAdd);
      }
    }
  };

  useEffect(() => {
    if (visible) {
      getTapStatus();
    }
  }, [visible]);

  const state = useMemo(() => {
    switch (tapStatus) {
      case 1:
        return (
          <span style={{ color: "purple" }}>
            {intl.formatMessage({ id: "collection.同步中…大概需要3分钟" })}
          </span>
        );
      case 2:
        return (
          <span style={{ color: "red" }}>
            {intl.formatMessage({ id: "collection.同步失败，请联系技术人员" })}
          </span>
        );
      case 3:
        return (
          <span style={{ color: "green" }}>
            {intl.formatMessage({ id: "collection.同步完成" })}
          </span>
        );
      case 4:
        return (
          <span style={{ color: "lightgray" }}>
            {intl.formatMessage({ id: "collection.无需同步" })}
          </span>
        );
      default:
        return "";
    }
  }, [tapStatus]);

  const onShare = async () => {
    const { id, itemSetName, description } = record;

    statistic({
      eventName: EventName.share_product_selection,
      param: {
        id
      }
    });

    setConfirmLoading(true);
    try {
      const detailRes = await userManage.requestItemSetInfo({
        itemSetId: id
      });
      const { code, result } = detailRes || {};
      if (code === 200 && result) {
        setIsBatchAdd(result.isBatchAdd);

        const { imageUrl } = result || {};
        const linkRes = await getShareLinkNew({
          title: itemSetName || "",
          content: description || "",
          imageUrl,
          url:
            style === 0
              ? `${getH5BaseUrl()}/pages/tiktok/selection?id=${record.id}&section=1${RegionParam(
                  "&"
                )}`
              : `${getH5BaseUrl()}/pages/tiktok/selection?id=${record.id}${RegionParam("&")}`
        });
        const { code } = linkRes || {};
        if (code === 200) {
          copy(linkRes.result, false);
          Modal.success({
            title: (
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage(
                    { id: "ttSample.您已成功复制xxx商品集分享链接" },
                    { setName: record.itemSetName || "" }
                  )
                }}
              />
            ),
            content: intl.formatMessage({ id: "ttSample.您可以将链接粘贴至任何对话框。" }),
            okText: intl.formatMessage({
              id: "button.Confirm"
            }),
            onOk: () => {
              handleCancel();
            }
          });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setConfirmLoading(false);
    }
  };

  return (
    <>
      <Modal
        open={visible}
        onOk={onShare}
        onCancel={handleCancel}
        destroyOnClose
        maskClosable={false}
        width={860}
        okText={intl.formatMessage({ id: "ttSample.分享" })}
        cancelText={intl.formatMessage({ id: "button.Cancel" })}
        confirmLoading={confirmLoading}
        title={intl.formatMessage({ id: "ttSample.分享商品集" })}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <h2 style={{ textAlign: "center", marginBottom: 0, color: "#ccc" }}>
              {intl.formatMessage({ id: "collection.专题页面预览" })}
            </h2>
            <iframe
              className='iframe'
              src={`${getH5BaseUrl()}/pages/tiktok/selection?id=${record.id}&section=1${RegionParam(
                "&"
              )}`}
              frameBorder='0'
              style={{ width: 375, height: 667 }}
            />
          </div>

          <div>
            <h2 style={{ textAlign: "center", marginBottom: 0, color: "#ccc" }}>
              {intl.formatMessage({ id: "collection.列表页面预览" })}
            </h2>
            <iframe
              className='iframe'
              src={`${getH5BaseUrl()}/pages/tiktok/selection?id=${record.id}${RegionParam("&")}`}
              frameBorder='0'
              style={{ width: 375, height: 667 }}
            />
          </div>
        </div>

        <Radio.Group
          style={{ display: "flex", justifyContent: "space-between" }}
          value={style}
          onChange={(e) => setStyle(e.target.value)}
        >
          <Radio.Button value={0} style={{ width: 375, textAlign: "center" }}>
            {intl.formatMessage({ id: "collection.样式一" })}
          </Radio.Button>
          <Radio.Button value={1} style={{ width: 375, textAlign: "center" }}>
            {intl.formatMessage({ id: "collection.样式二" })}
          </Radio.Button>
        </Radio.Group>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {state && (
            <>
              <div style={{ width: 375, textAlign: "center" }}>{state}</div>
              <div style={{ width: 375, textAlign: "center", color: "lightgray" }}>
                {isBatchAdd ? state : intl.formatMessage({ id: "collection.无需同步" })}
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};
