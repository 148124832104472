import { apiEnvStorage } from "./storage";

export const BASE_URLS = {
  DEV: "http://dev-h5.youpik.com",
  STAGING: "https://h5-static.test.uchoice.youpik.dev",
  PROD: "https://h5.uchoice.pro",
  PRE: "https://h5-static.pre.uchoice.pro"
};

export const NEW_BASE_URLS = {
  DEV: "https://h5.dev.uchoice.youpik.dev",
  STAGING: "https://h5.test.uchoice.youpik.dev",
  PROD: "https://h5.prod.uchoice.pro",
  PRE: "https://h5.pre.uchoice.pro"
};

export const getEnv = (): TEnvEnmu => {
  if (location.host.startsWith("localhost") || location.host.startsWith("192.")) {
    return process.env.API_ENV as TEnvEnmu;
  }

  const apiEnv: TEnvEnmu | null = apiEnvStorage.get();
  return apiEnv || (process.env.API_ENV as TEnvEnmu);
};

export function getH5BaseUrl() {
  const env: TEnvEnmu = getEnv();
  switch (process.env.API_ENV) {
    case "DEV":
      return BASE_URLS[env];
    case "STAGING":
      return BASE_URLS[env];
    case "PROD":
      return BASE_URLS.PROD;
    case "PRE":
      return BASE_URLS.PRE;
    default:
      return BASE_URLS.PROD;
  }
}

export function getNewH5BaseUrl() {
  const env: TEnvEnmu = getEnv();
  switch (process.env.API_ENV) {
    case "DEV":
      return NEW_BASE_URLS[env];
    case "STAGING":
      return NEW_BASE_URLS[env];
    case "PROD":
      return NEW_BASE_URLS.PROD;
    case "PRE":
      return NEW_BASE_URLS.PRE;
    default:
      return NEW_BASE_URLS.PROD;
  }
}
