import { apiEnvStorage, nationStorage } from "./storage";
import QueryString from "qs";

const getCurrentNation = () => {
  if (location.search) {
    const urlParams: any = QueryString.parse(location.search.substring(1, location.search.length));
    if (urlParams && urlParams.current_nation) {
      return urlParams.current_nation.toUpperCase();
    }
  }
  return nationStorage.get() || "TH";
};
// 接口域名的更改在这里处理
const getBaseUrls = (env: any) => {
  const currentNation = getCurrentNation();
  let url = "";
  console.log(env, "环境");
  switch (env) {
    case "DEV":
      url =
        currentNation === "VI"
          ? "https://api.dev.vn.youpik.dev/"
          : "https://api.dev.th.youpik.dev/";
      break;
    case "STAGING":
      url =
        currentNation === "VI"
          ? "https://api.test.vn.youpik.dev/"
          : "https://api.test.th.youpik.dev/";
      break;
    case "PROD":
      url = currentNation === "VI" ? "https://api.vn.uchoice.pro/" : "https://api.th.uchoice.pro/";
      break;
    case "PRE":
      url =
        currentNation === "VI"
          ? "https://api.pre.vn.uchoice.pro/"
          : "https://api.pre.th.uchoice.pro/";
      break;
    default:
      // url =
      //   currentNation === "VI" ? "https://api.pre.vn.uchoice.pro/" : "https://api.th.uchoice.pro/";
      url = currentNation === "VI" ? "https://api.vn.uchoice.pro/" : "https://api.th.uchoice.pro/";
  }
  return url;
};
// 有的页面有用到过这个，域名也更改了
export const BASE_URLS = {
  DEV:
    getCurrentNation() === "VI"
      ? "https://api.dev.vn.youpik.dev/"
      : "https://api.dev.th.youpik.dev/",
  STAGING:
    getCurrentNation() === "VI"
      ? "https://api.test.vn.youpik.dev/"
      : "https://api.test.th.youpik.dev/",
  PROD:
    getCurrentNation() === "VI" ? "https://api.pre.vn.uchoice.pro/" : "https://api.th.uchoice.pro/",
  PRE:
    getCurrentNation() === "VI"
      ? "https://api.pre.vn.uchoice.pro/"
      : "https://api.pre.th.uchoice.pro/"
};

const getEnv = (): TEnvEnmu => {
  if (location.host.startsWith("localhost") || location.host.startsWith("192.")) {
    return process.env.API_ENV as TEnvEnmu;
  }

  const apiEnv: TEnvEnmu | null = apiEnvStorage.get();
  return apiEnv || (process.env.API_ENV as TEnvEnmu);
};
const getUliveAdminBaseUrl = () => {
  const env: TEnvEnmu = getEnv();

  console.log("env:", env);

  switch (process.env.API_ENV) {
    case "DEV":
    case "STAGING":
      return getBaseUrls(env);
    case "PRE":
      return getBaseUrls("PRE");
    default:
      return getBaseUrls("PROD");
  }
};

const getUliveManageUrl = () => {
  if (
    location.href.includes("192.168") ||
    location.href.includes("127.0.0.1") ||
    location.href.includes("localhost")
  ) {
    return `${location.origin}/`;
  }

  const env: TEnvEnmu = getEnv();

  switch (env) {
    case "DEV":
      return "https://ops.dev.uchoice.youpik.dev/";
    case "STAGING":
      return "https://ops.test.uchoice.youpik.dev/";
    case "PROD":
      return "https://ops.uchoice.pro/";
    case "PRE":
      return "https://ops.pre.uchoice.youpik.dev/";
    default:
      return "https://ops.uchoice.pro/";
  }
};

const getTurnstileKey = () => {
  const env: TEnvEnmu = getEnv();
  return ["DEV", "STAGING", "PRE"].includes(env)
    ? "0x4AAAAAAAcy5EG2-uFHaHvX"
    : "0x4AAAAAAAfZlQ6MQRftthJX";
};

export { getUliveManageUrl, getEnv, getUliveAdminBaseUrl, getTurnstileKey };
