export default {
  通知方式: "通知方式",
  APP推送: "APP推送",
  标题: "标题",
  消息内容: "消息内容",
  打开跳转至: "打开跳转至",
  橱窗检测: "橱窗检测",
  数据榜单首页: "数据榜单首页",
  AI话题首页: "AI话题首页",
  AI定制: "AI定制",
  待发送: "待发送",
  已发送: "已发送",
  发送失败: "发送失败",
  推送时间: "推送时间",
  接收人: "接收人",
  所有用户: "所有用户",
  创建人: "创建人",
  查看自定义推送: "查看自定义推送",
  编辑自定义推送: "编辑自定义推送",
  创建自定义推送: "创建自定义推送",
  全部用户: "全部用户",
  指定用户: "指定用户",
  定时发送: "定时发送",
  即刻发送: "即刻发送",
  内容分类: "内容分类",
  通知: "通知",
  推荐: "推荐",
  频次: "频次",
  仅一次: "仅一次",
  每n天一次: "每 {day} 天一次",
  通知名称: "通知名称",
  触发节点: "触发节点",
  待审核转为待发货: "待审核转为待发货",
  待发货转为已发货: "待发货转为已发货",
  待发货转为待履约: "待发货转为待履约",
  橱窗账号登录状态异常: "橱窗账号登录状态异常",
  存在橱窗高佣待替换商品: "存在橱窗高佣待替换商品",
  没有橱窗登录过的用户: "存在橱窗高佣待替换商品",
  没有开启自动替换高佣的用户: "存在橱窗高佣待替换商品",
  未点击过加入推广计划按钮的用户: "存在橱窗高佣待替换商品",
  "榜单推荐-U选爆品": "榜单推荐-U选爆品",
  "榜单推荐-达人带货榜": "榜单推荐-达人带货榜",
  "榜单推荐-U选高佣": "榜单推荐-U选高佣",
  "榜单推荐-AI热点主题榜": "榜单推荐-AI热点主题榜",
  "榜单推荐-持续好货榜": "榜单推荐-持续好货榜",
  "榜单推荐-达人涨粉榜": "榜单推荐-达人涨粉榜",
  "榜单推荐-日销商品榜": "榜单推荐-日销商品榜",
  "榜单推荐-运营主推tap": "榜单推荐-运营主推tap",
  所有用户包括访客: "所有用户包括访客",
  申样用户: "申样用户",
  橱窗授权用户: "橱窗授权用户",
  已登录过橱窗的用户: "已登录过橱窗的用户",
  默认跳转: "默认跳转",
  指定H5页面: "指定H5页面",
  APP内页面: "APP内页面",
  单商品: "单商品",
  待发货订单列表: "待发货订单列表",
  待履约订单列表: "待履约订单列表",
  橱窗检测账号列表: "橱窗检测账号列表",
  高佣可替换商品列表: "高佣可替换商品列表",
  APP指定商品集落地页: "APP指定商品集落地页",
  APP数据持续好货榜: "APP数据持续好货榜",
  编辑内容: "编辑内容",
  确认关闭推送消息: "确认关闭推送消息",
  确认修改推送内容: "确认修改推送内容",
  "确认后内容将生效，你还要继续吗？":
    "<span style='color: red'>确认后内容将生效，</span>你还要继续吗？",
  正在编辑推送内容: "正在编辑推送内容",
  推送标题: "推送标题",
  请输入至少五个字符: "请输入至少五个字符",
  "确认后将不再推送此条消息，你还要继续吗？":
    "<span style='color: red'>确认后将不再推送此条消息，</span>你还要继续吗？",
  关闭推送: "关闭推送",
  开启推送: "开启推送",
  "TAP榜单-免费样品": "TAP榜单-免费样品",
  "TAP榜单-收藏夹": "TAP榜单-收藏夹",
  "TAP榜单-添加橱窗": "TAP榜单-添加橱窗",
  "TAP榜单- 30天畅销榜单": "TAP榜单- 30天畅销榜单",
  当天有订单审核被拒且无通过的: "当天有订单审核被拒且无通过的",
  当天有账号升级变动: "当天有账号升级变动",
  L0账号触发给样机制: "L0账号触发给样机制",
  新品到货: "新品到货",
  推广记录建议: "推广记录建议",
  每日6款推荐: "每日6款推荐",
  L0等级用户: "L0等级用户",
  过去30天有推广记录的用户: "过去30天有推广记录的用户",
  有授权记录的用户: "有授权记录的用户"
};
