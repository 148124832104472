import { randomId as uuid } from "../randomId";

export class TraceId {
  private static randomId: string;
  private static count: number;

  static getId() {
    if (!this.randomId) {
      this.randomId = uuid();
    }
    if (this.count === undefined) {
      this.count = 1;
    }
    return `ops.${this.randomId}.${this.count}`;
  }

  static increase() {
    this.count++;
  }
}
